const config = {
  languages: [
    { mnemonic: 'nb', name: 'Bokmål', placeholder: 'Søk på bokmål', flag: '/img/flags/nb.svg', hasLevel: true },
    { mnemonic: 'nn', name: 'Nynorsk', placeholder: 'Søk på nynorsk eller bokmål', flag: '/img/flags/nb.svg', hasLevel: false },
    { mnemonic: 'en', name: 'Engelsk', placeholder: 'Søk på engelsk eller norsk', flag: '/img/flags/en.svg', hasLevel: true },
    { mnemonic: 'fr', name: 'Fransk', placeholder: 'Søk på fransk eller norsk', flag: '/img/flags/fr.svg', hasLevel: true },
    { mnemonic: 'es', name: 'Spansk', placeholder: 'Søk på spansk eller norsk', flag: '/img/flags/es.svg', hasLevel: true },
    { mnemonic: 'it', name: 'Italiensk', placeholder: 'Søk på italiensk eller norsk', flag: '/img/flags/it.svg', hasLevel: true },
    { mnemonic: 'de', name: 'Tysk', placeholder: 'Søk på tysk eller norsk', flag: '/img/flags/de.svg', hasLevel: true },
    { mnemonic: 'ar', name: 'Arabisk', placeholder: 'Søk på arabisk eller norsk', flag: '/img/flags/ar.svg', hasLevel: false },
    { mnemonic: 'ru', name: 'Russisk', placeholder: 'Søk på russisk eller norsk', flag: '/img/flags/ru.svg', hasLevel: false }
  ],
  searchconfig: {
    nb: [
      {
        level: 1,
        pubs: [1004]
      },
      {
        level: 2,
        pubs: [1007]
      }
    ],
    nn: [
      {
        level: 1,
        pubs: [1005]
      },
      {
        level: 2,
        pubs: []
      }
    ],
    en: [
      {
        level: 1,
        pubs: [1008, 1009]
      },
      {
        level: 2,
        pubs: [1018, 1019]
      }
    ],
    de: [
      {
        level: 1,
        pubs: [1014, 1015]
      },
      {
        level: 2,
        pubs: [1024, 1025]
      }
    ],
    fr: [
      {
        level: 1,
        pubs: [1010, 1011]
      },
      {
        level: 2,
        pubs: [1020, 1021]
      }
    ],
    es: [
      {
        level: 1,
        pubs: [1012, 1013]
      },
      {
        level: 2,
        pubs: [1022, 1023]
      }
    ],
    it: [
      {
        level: 1,
        pubs: [1016, 1017]
      },
      {
        level: 2,
        pubs: [1026, 1027]
      }
    ],
    ar: [
      {
        level: 1,
        pubs: [1028, 1029]
      },
      {
        level: 2,
        pubs: []
      }
    ],
    ru: [
      {
        level: 1,
        pubs: [1032, 1033]
      },
      {
        level: 2,
        pubs: []
      }
    ]
  },
  minQuerySuggestions: 2,
  api: process.env.NEXT_PUBLIC_API,
  localApi: process.env.NEXT_PUBLIC_LOCAL_API,
  releaseNo: process.env.NEXT_PUBLIC_RELEASE_NO,
  url: process.env.NEXT_PUBLIC_URL,
  isProduction: process.env.NODE_ENV === 'production',
  allowedRedirects: [
    '/access/login',
    '/access/logout',
    '/access/show',
    '/access/register',
    '/access/register_evaluation',
    '/access/register_access_code',
    '/access/forgotten_password',
    '/user/login',
    '/user/new',
    '/user/edit',
    '/user/mark_for_deletion',
    '/payment/start',
    '/site/membership_terms'
  ]
}

export default config
